import { Icon } from "../Icon";
import { Text } from "../Text";
import type { Breadcrumb } from "./Breadcrumbs";
import {
  LinkMobile,
  List,
  ListItem,
  NextLinkMobile,
} from "./Breadcrumbs.styles";
import { Separator } from "./Separator";

type Props = {
  firstBreadcrumb: Breadcrumb;
  homeUrl: string;
  lastBreadcrumb: Breadcrumb;
  /**Whether to use h1 html element for the last breadcrumb or not */
  includeH1?: boolean;
  shouldUseNextLink?: boolean;
};
export const BreadcrumbsMobile = ({
  firstBreadcrumb,
  homeUrl,
  includeH1 = false,
  lastBreadcrumb,
  shouldUseNextLink = false,
}: Props) => {
  const LinkComponent = shouldUseNextLink ? NextLinkMobile : LinkMobile;

  return (
    <List
      data-testid="breadcrumbs"
      itemScope={true}
      itemType="https://schema.org/BreadcrumbList"
      style={{ minHeight: "18px" }} // Todo: remove this https://xxlsports.atlassian.net/browse/XD-15863
    >
      <ListItem
        itemProp="itemListElement"
        itemScope={true}
        itemType="https://schema.org/ListItem"
      >
        <LinkComponent
          href={`${homeUrl}${firstBreadcrumb.url}`}
          itemProp="item"
          scroll={false}
        >
          <Icon name="CaretLeft" color="webBlack" />
          <Text color="webBlack" itemProp="name">
            {firstBreadcrumb.name}
          </Text>
        </LinkComponent>
        <meta itemProp="position" content="1" />
        <Separator />
      </ListItem>
      <ListItem>
        <LinkComponent
          href={`${homeUrl}${lastBreadcrumb.url}`}
          itemProp="item"
          scroll={false}
        >
          <Text
            as={includeH1 ? "h1" : undefined}
            margin={includeH1 ? "0" : undefined}
            fontFamily="medium"
            color="webBlack"
            itemProp="name"
          >
            {lastBreadcrumb.name}
          </Text>
        </LinkComponent>
        <meta itemProp="position" content="2" />
      </ListItem>
    </List>
  );
};
