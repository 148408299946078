import Link, { type LinkProps } from "next/link";
import { type PropsWithChildren } from "react";

/**
 * Please see https://bitbucket.org/xxldevelopment/xxl-frontend-service/src/master/storefront/react-app/src/components/NextComponentsStubs/README.md
 */
export const NextLink = (
  props: PropsWithChildren<LinkProps & { itemProp?: string }>
) => (
  <Link {...props} prefetch={props.prefetch ?? false}>
    {props.children}
  </Link>
);
